<template>
    <el-dialog
        width="500px"
        top="50px"
        :visible.sync="visible"
        :before-close="() => !$wait.is('*') && $emit('close')"
        :close-on-click-modal="false"
        @closed="$emit('closed')"
    >
        <span slot="title">
            <div class="absolute w-full top-0 inset-x-0 border-b rounded-t-lg p-4 bg-gray-100">
                <p>
                    {{ $t('employee_details.assign_to_address') }}
                </p>
            </div>
        </span>

        <div class="mb-20 mt-8 px-10">
            <el-form ref="form" :model="formData" :rules="rules" class="w-full" label-position="top">
                <ClientsAddressesDropdown ref="clientsAddressesDropdown" @change="addressSelected" />
            </el-form>
        </div>

        <div class="flex justify-end rounded-b-lg border-t w-full p-3 bg-gray-100 absolute bottom-0 inset-x-0">
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal">{{ $t('common.cancel') }}</el-button>
                <el-button type="primary" :loading="$wait.is('loading.add_reminder')" :disabled="formData.selectedAddress === null" @click="attach">{{ $t('assign') }}</el-button>
            </span>
        </div>
    </el-dialog>
</template>

<script>
import Api from './assigned_addresses.api';

export default {
    components: {
        ClientsAddressesDropdown: () => import(/* webpackChunkName: "ClientsAddressesDropdown" */ '@/components/ClientsAddressesDropdown'),
    },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        employeeUuid: {
            type:     String,
            required: true,
        },
    },

    data() {
        return {
            formData: {
                selectedAddress: null,
            },
            rules: {
                selectedAddress: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    methods: {
        async attach() {
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            try {
                this.$wait.start('loading.add_reminder');
                await Api.attach(this.employeeUuid, this.formData.selectedAddress);

                this.$notify.success({ title: this.$t('common.success') });
                this.$emit('refresh');
                this.closeModal();
            } finally {
                this.$wait.end('loading.add_reminder');
            }
        },

        addressSelected(address) {
            this.formData.selectedAddress = address.id;
        },

        closeModal() {
            this.$refs.form.resetFields();
            this.$refs.clientsAddressesDropdown.resetSelection();
            this.formData = {
                selectedAddress: null,
            };
            this.$emit('close');
        },
    },
};
</script>
<style scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
}
</style>
